import { Content } from 'components/atoms/Content';
import { Address } from '@magicship/utils';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Breakpoints } from 'styles/theme';
import { CalculatorAction } from './CalculatorAction';
import { Location } from './Location';
import { ShippingOptions } from './ShipmentOptions/ShippingOptions';
import { ShippingSection } from './ShippingSection';
import { initialState, ShipmentInput } from '@magicship/utils/shipment';
import { FormProvider, useForm } from 'react-hook-form';
import { errorToMessage } from '@magicship/utils/util';
import { GRAPHQL_AUTH_MODE, API } from '@aws-amplify/api';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import * as queries from 'src/graphql/queries';
import { GetRatesQueryVariables, PackageType, GetRatesQuery, ShippingOption, ShippingRatesResult } from '~graphql/API';
import { Button } from 'components/atoms/Button';
import { useCurrentUser } from 'components/hooks/useCurrentUser';
import Router from 'next/router';
import { useTracking } from 'components/hooks/useTracking';
import { useModalContext } from 'components/parts/login/ModalContext';
import { findLocationByCity, findLocationByPostalCode } from 'components/utils/geocoding';

const Title = styled.h2`
    text-transform: uppercase;
    font-weight: 900;
    font-size: 25px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
    text-align: left;
    align-self: stretch;
    padding-bottom: 12px;

    @media ${Breakpoints.lg} {
        text-align: center;
        font-size: 30px;
    }
`;

const Container = styled.section`
    display: flex;
    flex-direction: column;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${Breakpoints.lg} {
        flex-direction: row;
    }
`;

const Form = styled.form`
    width: 100%;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media ${Breakpoints.lg} {
        padding-top: 0;
    }
`;

const PromptToSeeOtherCarriers = styled.div`
    display: flex;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
`;

export const CalculatorSection: React.FunctionComponent = () => {
    const { t } = useTranslation('home');

    const methods = useForm<ShipmentInput>({
        defaultValues: initialState,
        mode: 'onTouched',
    });

    const user = useCurrentUser();
    const { showModal } = useModalContext();

    const pixel = useTracking();

    const [error, setError] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [results, setResults] = useState<ShippingRatesResult | null>(null);
    const [selectedOption, setSelectedOption] = useState<ShippingOption | null>(null);
    const [lastState, setLastState] = useState<ShipmentInput | null>(null);
    const [requireCityFrom, setRequireCityFrom] = useState(false);
    const [requireCityTo, setRequireCityTo] = useState(false);

    const isDirty = methods.formState.isDirty;

    useEffect(() => {
        if (isDirty) {
            setResults(null);
            setSelectedOption(null);
        }
    }, [isDirty]);

    const onSubmit = methods.handleSubmit(async (value) => {
        setInProgress(true);
        setError('');
        setSelectedOption(null);
        setResults(null);
        try {
            const ensureProvinceCode = async (location: Address, requiresCity?: boolean) => {
                if (location.city && location.countryCode && location.postalCode) {
                    return location;
                }
                let result = await findLocationByPostalCode(location.postalCode, location.countryCode);
                if (!result && requiresCity) {
                    result = await findLocationByCity(location.city ?? '', location.countryCode);
                }
                if (result) {
                    location.stateCode = result.stateCode;
                    location.countryCode = result.countryCode;
                    location.city = result.city;
                    return location;
                }
                return null;
            };

            const results = await Promise.all([
                ensureProvinceCode(value.from, requireCityFrom),
                ensureProvinceCode(value.to, requireCityTo),
            ]);
            if (!results[0] || !results[1]) {
                setError(t('We failed to resolve the city from the provided postal code. Please enter the city name.'));
                if (!results[0]) {
                    setRequireCityFrom(true);
                }
                if (!results[1]) {
                    setRequireCityTo(true);
                }
                return;
            }
            const variables: GetRatesQueryVariables = {
                input: {
                    to: {
                        stateCode: value.to.stateCode,
                        countryCode: value.to.countryCode,
                        postalCode: value.to.postalCode,
                        city: value.to.city,
                        address1: value.to.address1 ?? '',
                        address2: value.to.address2 ?? '',
                        commercial: value.to.commercial,
                    },
                    from: {
                        stateCode: value.from.stateCode,
                        countryCode: value.from.countryCode,
                        postalCode: value.from.postalCode,
                        city: value.from.city,
                        address1: value.from.address1 ?? '',
                        address2: value.from.address2 ?? '',
                        commercial: value.from.commercial,
                    },
                    packageType: PackageType.box,
                    boxes: value.boxes.map((x) => {
                        if (!x.weight.unit) {
                            throw new Error('Weight unit is required');
                        }
                        if (!x.dimensions.unit) {
                            throw new Error('Dimensions unit is required');
                        }
                        return {
                            weight: { value: parseFloat(x.weight.value), unit: x.weight.unit },
                            dimensions: {
                                width: parseFloat(x.dimensions.width),
                                height: parseFloat(x.dimensions.height),
                                length: parseFloat(x.dimensions.length),
                                unit: x.dimensions.unit,
                            },
                        };
                    }),
                    getOriginalPrice: true,
                },
            };
            const getRatesRes = (await API.graphql({
                query: queries.getRates,
                variables,
                authMode: user ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM,
            })) as GraphQLResult<GetRatesQuery>;
            if (getRatesRes.data?.getRates) {
                pixel.addToCart();
                setResults(getRatesRes.data?.getRates);
            }
            setLastState(value);
            methods.reset(value);
        } catch (e) {
            setError(errorToMessage(e));
        } finally {
            setInProgress(false);
        }
    });

    const handleCompleteShipment = useCallback(() => {
        Router.push(
            {
                pathname: '/new-shipment',
                query: {
                    state: JSON.stringify(lastState),
                },
            },
            '/new-shipment',
            { shallow: false }
        );
    }, [lastState]);

    const handleSignup = useCallback(() => {
        Router.push(
            {
                pathname: '/',
                query: {
                    signup: true,
                    state: JSON.stringify(lastState),
                },
            },
            '/',
            { shallow: true }
        );
        showModal('signup');
    }, [lastState, showModal]);

    return (
        <Container>
            <Content>
                <Title>{t('Try it out')}</Title>
                <FormProvider {...methods}>
                    <Form onSubmit={onSubmit}>
                        <InputContainer>
                            <Location requireCityFrom={requireCityFrom} requireCityTo={requireCityTo} />
                            <ShippingSection />
                        </InputContainer>
                        <CalculatorAction inProgress={inProgress} error={error} />
                    </Form>
                </FormProvider>
            </Content>
            {results && (
                <ShippingOptions
                    isPOBoxAddress={false}
                    isLikelyRemoteAddress={false}
                    showOriginalPrice
                    scrollIntoView
                    data={results}
                    selectedOption={selectedOption}
                    onChange={setSelectedOption}
                    hideAlias
                />
            )}
            {results && !user && (
                <PromptToSeeOtherCarriers>
                    {t('Sign up or log in to see all available carriers, and to add your own carriers')}
                </PromptToSeeOtherCarriers>
            )}
            {selectedOption && (
                <Actions>
                    {user ? (
                        <Button kind="secondary" fill="solid" size="lg" onClick={handleCompleteShipment}>
                            {t(' Complete Shipment')}
                        </Button>
                    ) : (
                        <Button kind="secondary" fill="solid" size="lg" onClick={handleSignup}>
                            {t('Sign up Now')}
                        </Button>
                    )}
                </Actions>
            )}
        </Container>
    );
};
